import { Box , Typography,Button} from '@mui/material'
import {React,useEffect,useState} from 'react'
import { Homeslider } from './Homeslider'
import { Link } from 'react-router-dom';

//  commit
export const Popularcollection = () => {

  return (
      <Box sx={{display:'flex', justifyContent:'center', flexDirection:'column', bgcolor:'', paddingTop:'60px'}}>
        <Box sx={{display:'flex' , flexDirection:{sm:'row',xs:'column'}, ml:'0px', justifyContent:'space-around', alignItems:'center'}}>
          <Box sx={{width:{sm:'50%',xs:'100%'}, padding:{xs:'2rem ', sm:''} }} >
            <Typography variant='h4' sx={{width:{xs:'100%'}, fontSize: { xs: '30px', sm: '45px' },fontFamily: 'var(--primary-font) !important', fontWeight:'550' }}>
              <span
                style={{
                  color: 'var(--trinary-color)',
                  fontWeight: 550,
                  fontFamily: 'inherit',
                }}
                className="text-animation"
              >
                Popular
              </span> Work
            </Typography>
            <Typography variant="body2" sx={{mt:'20px', fontSize:{sm:'18px',xs:"16px"}}} >
              Our popular creations are crafted with utmost care for
              numerous startup brands! Begin your clothing brand
              journey with us.
            </Typography>
          </Box>

          <Button  component={Link} to="/product" sx={{
              
              display:{xs:'none', sm:'flex'},
              background:'var(--trinary-color)',
              mt: {xs:'0px', sm:'30px'},
              borderRadius:'30px',
              '&:hover': {
                backgroundColor: 'var(--secondary-color)', 
                color:'',// Add hover effect
                transition: 'background-color 0.3s ease-in-out',
              },
            }} variant="contained">Know More</Button>
        </Box>

        <Box sx={{alignItems:{xs:'center'},flexDirection:{xs:'column'},display:{xs:'flex'} }} >
          <Homeslider />
          
          <Button  component={Link} to="/product" sx={{
              display:{sm:'none', xs:'flex'},
              width:{xs:'120px'},
              fontSize:{xs:'12px'},
              background:'var(--secondary-color)',
              mt: {xs:'10px', sm:'30px'},
              borderRadius:'30px',
              '&:hover': {
                backgroundColor: '#1E1E1E', 
                color:'',// Add hover effect
                transition: 'background-color 0.3s ease-in-out',
              },
            }} variant="contained">Know More</Button>
        </Box>
      </Box>
    )
}
